
import { onMounted, onUnmounted, watch, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default {
  name: "Dashboards",

  setup() {
    const route = useRoute();
    const item = ref(null);

    watch(
      () => [route.params.item_id],
      async () => {
        await getItem();
      }
    );
    const getItem = () => {
      if (route.params.item_id) {
        ApiService.get("/api/dashboards/" + route.params.item_id).then(
          ({ data }) => {
            item.value = data;
          }
        );
      }
    };

    onMounted(() => {
      getItem();
    });

    onUnmounted(() => {
      item.value = null;
    });

    return {
      item,
    };
  },
};
