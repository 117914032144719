import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.item)
    ? (_openBlock(), _createElementBlock("iframe", {
        key: 0,
        id: "embed-1",
        allowfullscreen: "yes",
        src: $setup.item.url,
        style: {"width":"100%","height":"100%","border":"none","margin":"0","padding":"0","overflow":"hidden","z-index":"999999"}
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}